export const realtorData = {
  email: 'immobiliare@unne.cl',
  phone:'+56997871527'
  // email: 'contacto@accionpanal.com',
};

export const footerData = {
  email: 'immobiliare@unne.cl',
  phone:'997871527',
  wsp:'997871527',
}
